<template>
    <div class="lunbo">
        <el-form ref="mainForm" :model="mainForm" :rules="rules" label-width="80px">
            <el-form-item label="姓名" prop="fullName">
                <el-input v-model="mainForm.fullName" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobile">
                <el-input v-model="mainForm.mobile" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
                <el-input v-model="mainForm.username" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item v-if="!this.$route.query.userId" label="密码" prop="password">
                <el-input type="password" v-model="mainForm.password" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item v-if="!this.$route.query.userId" label="确认密码">
                <el-input type="password" v-model="mainForm.password2" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="goBack">取消</el-button>
                <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import imageUploader from "@/components/imageUploader";
export default {
    components: { imageUploader },
    data() {
        return {
            mainForm: {
                fullName: "",
                mobile: "",
                username: "",
                password: "",
                password2: "",
            },
            rules: {
                fullName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
                mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
                username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
                password: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            userId: null,
        };
    },
    mounted() {
        let userId = this.$route.query.userId;
        if (userId) {
            this.userId = userId;
            this.$http({
                url: "/sys/admin/getAdmin",
                data: {
                    id: userId,
                },
            }).then((res) => {
                this.mainForm.fullName = res.data.fullName;
                this.mainForm.mobile = res.data.mobile;
                this.mainForm.username = res.data.username;
            });
        }
    },
    methods: {
        // 后退
        goBack: function() {
            this.$router.go(-1);
        },
        submit: function() {
            if (this.mainForm.password != this.mainForm.password2) {
                this.$message({
                    message: "两次密码输入不一致",
                    type: "error",
                });
                return;
            }
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    if (this.userId == null) {
                        // 新增
                        this.$http({
                            url: "/sys/admin/create",
                            data: {
                                fullName: this.mainForm.fullName,
                                mobile: this.mainForm.mobile,
                                username: this.mainForm.username,
                                password: this.mainForm.password,
                            },
                        }).then((res) => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.$router.push("/admin/list");
                        });
                    } else {
                        // 更新
                        this.$http({
                            url: "/sys/admin/update",
                            data: {
                                id: this.userId,
                                fullName: this.mainForm.fullName,
                                mobile: this.mainForm.mobile,
                                username: this.mainForm.username,
                            },
                        }).then((res) => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.$router.push("/admin/list");
                        });
                    }
                }
            });
        },
    },
};
</script>
